import React, {
  ChangeEvent, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import {
  TextField, FormControl, InputLabel, Select, MenuItem, Button, Typography,
} from '@material-ui/core';
import fileToBase64 from '../util/function/image';
import { storage } from '../util/firebase';

type Props = {
  name: string;
  setName: (newState: string) => void;
  nameEng: string;
  setNameEng: (newState: string) => void;
  introduction: string;
  setIntroduction: (newState: string) => void;
  introductionEng: string;
  setIntroductionEng: (newState: string) => void;
  department: string;
  setDepartment: (newState: string) => void;
  departmentEng: string;
  setDepartmentEng: (newState: string) => void;
  generation: string;
  setGeneration: (newState: string) => void;
  active: 0 | 1;
  setActive: (newState: 0 | 1) => void;
  submit: () => void;
  onChangePictureFile: (e: ChangeEvent<HTMLInputElement>) => void;
  onClickRemovePicture: () => void;
  file: File | null;
  originalPicture: string | null;
}

const MemberDetail: React.FC<Props> = ({
  name,
  setName,
  nameEng,
  setNameEng,
  introduction,
  setIntroduction,
  introductionEng,
  setIntroductionEng,
  department,
  setDepartment,
  departmentEng,
  setDepartmentEng,
  generation,
  setGeneration,
  active,
  setActive,
  submit,
  onChangePictureFile,
  onClickRemovePicture,
  file,
  originalPicture,
}) => {
  const inputProps = (state: string, setState: (newState: string) => void) => ({
    value: state,
    onChange: (e: ChangeEvent<HTMLInputElement>) => setState(e.target.value),
  });

  const [pictureString, setPictureString] = useState<string | null>(null);
  useEffect(() => {
    (async () => {
      if (file) {
        setPictureString(await fileToBase64(file));
      } else {
        setPictureString(null);
      }
    })();
  }, [file]);

  useEffect(() => {
    (async () => {
      if (originalPicture) {
        const gsRef = storage.refFromURL(originalPicture);
        gsRef.getDownloadURL().then((url) => {
          setPictureString(url);
        });
      } else {
        setPictureString(null);
      }
    })();
  }, [originalPicture]);

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <TextField id="member-name" label="이름" {...inputProps(name, setName)} />
      <TextField id="member-name-english" label="이름(영어)" {...inputProps(nameEng, setNameEng)} />
      <TextField id="member-department" label="학부" {...inputProps(department, setDepartment)} />
      <TextField id="member-department-english" label="학부(영어)" {...inputProps(departmentEng, setDepartmentEng)} />
      <TextField id="member-introduction" label="소개" {...inputProps(introduction, setIntroduction)} />
      <TextField id="member-introduction-english" label="소개(영어)" {...inputProps(introductionEng, setIntroductionEng)} />

      <FormControl>
        <InputLabel id="member-active-label">활동 상태</InputLabel>
        <Select
          labelId="member-active-label"
          id="member-active"
          value={active}
          onChange={(e) => setActive(Number(e.target.value) as 0 | 1)}
        >
          <MenuItem value={1}>활동</MenuItem>
          <MenuItem value={0}>비활동</MenuItem>
        </Select>
      </FormControl>

      <FormControl>
        <InputLabel id="member-generation-label">활동 기수</InputLabel>
        <Select
          labelId="member-generation-label"
          id="member-generation"
          value={generation}
          onChange={(e) => setGeneration(String(e.target.value))}
        >
          {['15', '14.5', '14', '13.5', '13', '12.5', '12', '11.5', '11', '10.5', '10', '9.5', '9'].map(targetGeneration => (
            <MenuItem value={targetGeneration} key={targetGeneration}>
              {targetGeneration}
              기
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl>
        <Typography variant="subtitle1" gutterBottom>사진 등록</Typography>
        <Input
          type="file"
          onChange={onChangePictureFile}
          accept="image/*"
        />
        <MButton onClick={onClickRemovePicture} variant="contained" size="small">사진 비우기</MButton>
        <Typography variant="subtitle2" gutterBottom>(사진 미리보기)</Typography>
        <Image src={pictureString ?? undefined} alt="" />
      </FormControl>

      <Button variant="contained" color="primary" onClick={submit}>
        확인
      </Button>
    </Form>
  );
};

export default MemberDetail;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Image = styled.img`
  width: 100%;
  max-width: 400px;
`;

const Input = styled.input`
  width: 10rem;
  margin-bottom: 0.5rem;
`;

const MButton = styled(Button)`
  width: 10rem;
`;
